@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

#duration-btn {
  border-radius: 10px;
  width: 100%;
}

#duration-btn:hover {
  background-color: $primary-light;
}

#btn-text {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 0.5rem !important;
  cursor: pointer;
}

#automation-btn,
#asset-btn {
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  padding: 8px;
}

#automation-btn:hover,
#asset-btn:hover {
  background-color: $primary-light;
}

#centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

#hf-card,
#arrow-card {
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.3);
  text-align: center;
  justify-content: center;
  border-radius: 50% !important;
  width: 70px;
  height: 70px;
  font-size: 14px;
  font-weight: 500;
}

#arrow-card {
  box-shadow: none;
  background-color: transparent;
  font-size: 30px;
}

.sl-circle {
  border: 10px solid $secondary-light !important;
  border-top: 10px solid $primary !important;
}

#policy-price {
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}

.only-desktop {
  @include respond-to(sm) {
    display: none;
  }
}

.only-mobile {
  @include for-size(desktop-up) {
    display: none;
  }
  @include for-size(tablet-landscape-up) {
    display: none;
  }
  @include for-size(tablet-portrait-up) {
    display: none;
  }
}
