#active-policies-card {
  padding: 20px;
  background-color: #b7cfea;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.42);
}

#max-payout-number,
#automation-txt {
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

#max-payout-number {
  font-size: 1.75rem;
  vertical-align: middle;
}

#liq-under-text,
#expiration-text {
  color: #545f71;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 5px;
}

#expiration-text {
  color: black;
}
