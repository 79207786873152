#position-card {
  padding: 20px;
  background-color: #b7cfea;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.42);
}

#collaretal-base-number {
  color: #545f71;
  font-size: 1.75rem;
  font-weight: 500;
}
