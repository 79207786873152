@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-wallet-card {
  padding: 20px;
  text-align: center;
  width: 75%;
  background-color: #b7cfea !important;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.42) !important;

  @include respond-to(sm) {
    width: 90%;
  }
}
